import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: [
    "vid",
    "rules",
    "initialValue",
    "label",
    "id",
    "name",
    "inputclass",
    "required",
  ],

  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("update-field", { newVal, name: this.name });
    },
    // Handles external model changes.
    initialValue(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.initialValue) {
      if (this.$attrs.type !== "file") {
        this.innerValue = this.initialValue;
      } else {
        this.innerValue = [];
      }
    }
  },
};
