import { extend } from "vee-validate";
import i18n from "../../i18n";
import {
  required,
  email,
  numeric,
  image,
  ext,
  between,
  alpha,
  alpha_dash,
  max,
  confirmed,
  double,
  regex,
  alpha_num,
  is_not,
  alpha_spaces,
} from "vee-validate/dist/rules";

export default () => {
  return [
    extend("email", email),
    extend("numeric", numeric),
    extend("alpha_num", alpha_num),
    extend("alpha_spaces", alpha_spaces),
    extend("ext", ext),
    extend("is_not", is_not),
    extend("image", image, {
      message: i18n.t("required_img"),
    }),

    extend("required", {
      ...required,
      message: i18n.t("required"),
    }),

    extend("between", between),
    extend("max", max),
    extend("alpha", alpha),
    extend("confirmed", confirmed),
    extend("isValidPhoneNumber", (value) => {
      const pattern = new RegExp(/^[+ 0-9]{11,13}$/);
      return pattern.test(value) ? true : "Phone number is invalid";
    }),
    extend("alpha_dash", alpha_dash),
    extend("double", double),
    extend("regex", regex),
  ];
};
