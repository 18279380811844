<template>
  <ValidationProvider
    ref="validator"
    :vid="name"
    :name="name"
    :rules="rules"
    v-slot="{ valid, errors }"
  >
    <div :class="inputclass" class="FormSelect">
      <label v-if="locale == 'fr'" class="form__inputContainer__label" :for="id"
        >{{ label }} <span v-if="required" class="text-secondary">*</span>
      </label>
      <label
        v-else-if="locale == 'ar'"
        class="form__inputContainer__label"
        :for="id"
        ><span v-if="required" class="text-secondary">*</span>{{ label }}
      </label>
      <b-form-select
        class="form__inputContainer__select"
        :id="id"
        v-bind="$attrs"
        :state="errors[0] ? false : valid ? true : null"
        v-model="innerValue"
      >
        <!-- un-named slot for options -->
        <slot />
      </b-form-select>
    </div>
    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
  </ValidationProvider>
</template>

<script>
import inputMixin from "../mixins/input-mixin";
export default {
  mixins: [inputMixin],
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>
