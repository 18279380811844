<template>
  <button
    class="formContainer__btn btn"
    @click="$emit('onClick')"
    :class="formbtnClass"
  >
    {{ text }}
  </button>
</template>

<script>
export default { props: ["text", "formbtnClass"] };
</script>

<style lang="scss" scoped></style>
