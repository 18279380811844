<template>
  <div class="formContainer__textareaContainer">
    <ValidationProvider
      :vid="id"
      :name="$attrs.name"
      :rules="rules"
      v-slot="{ valid, errors }"
    >
      <label v-if="locale == 'fr'" class="form__inputContainer__label" :for="id"
        >{{ label }} <span v-if="required" class="text-secondary">*</span>
      </label>
      <label
        v-else-if="locale == 'ar'"
        class="form__inputContainer__label"
        :for="id"
        ><span v-if="required" class="text-secondary">*</span>{{ label }}
      </label>
      <b-form-textarea
        class="formContainer__textareaContainer__textarea"
        v-bind="$attrs"
        :state="errors[0] ? false : valid ? true : null"
        v-model="innerValue"
        rows="3"
        max-rows="6"
      ></b-form-textarea>

      <b-form-invalid-feedback id="inputLiveFeedback">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </ValidationProvider>
  </div>
</template>

<script>
import inputMixin from "../mixins/input-mixin";
export default {
  mixins: [inputMixin],
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>
