<template>
  <ValidationProvider
    :vid="$attrs.name"
    :name="$attrs.name"
    :rules="rules"
    v-slot="{ valid, errors }"
  >
    <div class="form__inputContainer">
      <label v-if="locale == 'fr'" class="form__inputContainer__label" :for="id"
        >{{ label }} <span v-if="required" class="text-secondary">*</span>
      </label>
      <label
        v-else-if="locale == 'ar'"
        class="form__inputContainer__label"
        :for="id"
        ><span v-if="required" class="text-secondary">*</span>{{ label }}
      </label>

      <b-form-input
        class="form__inputContainer__input"
        v-model="innerValue"
        v-bind="$attrs"
        :state="errors[0] ? false : valid ? true : null"
        :id="id"
      >
      </b-form-input>
      <b-form-invalid-feedback id="inputLiveFeedback">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </div>
  </ValidationProvider>
</template>

<script>
import inputMixin from "../mixins/input-mixin";
export default {
  mixins: [inputMixin],
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped></style>
